<template>
  <div class="w-full h-full order-1 flex flex-col justify-end items-center">
    <div class="w-full fixed bottom-0">
      <svg
        width="100%"
        height="100%"
        id="svg"
        viewBox="0 0 1440 500"
        xmlns="http://www.w3.org/2000/svg"
        class="transition duration-300 ease-in-out delay-150"
      >
        <defs>
          <linearGradient id="gradient" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="5%" stop-color="#00bfff44"></stop>
            <stop offset="95%" stop-color="#0059ff44"></stop>
          </linearGradient>
        </defs>
        <path
          d="M 0,500 C 0,500 0,100 0,100 C 77.8370765832106,111.11064064801178 155.6741531664212,122.22128129602356 211,125 C 266.3258468335788,127.77871870397644 299.1404639175258,122.22551546391752 349,111 C 398.8595360824742,99.77448453608248 465.76399116347557,82.87665684830634 523,76 C 580.2360088365244,69.12334315169366 627.8035714285714,72.26785714285712 690,83 C 752.1964285714286,93.73214285714288 829.0217231222385,112.0519145802651 904,109 C 978.9782768777615,105.9480854197349 1052.1095360824743,81.52448453608247 1111,87 C 1169.8904639175257,92.47551546391753 1214.5401325478645,127.85014727540502 1267,135 C 1319.4598674521355,142.14985272459498 1379.7299337260679,121.07492636229749 1440,100 C 1440,100 1440,500 1440,500 Z"
          stroke="none"
          stroke-width="0"
          fill="url(#gradient)"
          class="transition-all duration-300 ease-in-out delay-150 path-0"
        ></path>
        <defs>
          <linearGradient id="gradient" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="5%" stop-color="#00bfff66"></stop>
            <stop offset="95%" stop-color="#0059ff66"></stop>
          </linearGradient>
        </defs>
        <path
          d="M 0,500 C 0,500 0,200 0,200 C 67.71170839469806,211.1809646539028 135.42341678939613,222.3619293078056 201,223 C 266.57658321060387,223.6380706921944 330.01804123711344,213.73324742268042 381,212 C 431.98195876288656,210.26675257731958 470.50441826215024,216.7050810014728 525,222 C 579.4955817378498,227.2949189985272 649.9642857142857,231.44642857142858 708,218 C 766.0357142857143,204.55357142857142 811.6384388807069,173.50920471281296 867,176 C 922.3615611192931,178.49079528718704 987.4819587628867,214.51675257731955 1064,225 C 1140.5180412371133,235.48324742268045 1228.4337260677469,220.4237849779087 1293,212 C 1357.5662739322531,203.5762150220913 1398.7831369661267,201.78810751104567 1440,200 C 1440,200 1440,500 1440,500 Z"
          stroke="none"
          stroke-width="0"
          fill="url(#gradient)"
          class="transition-all duration-300 ease-in-out delay-150 path-1"
        ></path>
        <defs>
          <linearGradient id="gradient" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="5%" stop-color="#00bfff88"></stop>
            <stop offset="95%" stop-color="#0059ff88"></stop>
          </linearGradient>
        </defs>
        <path
          d="M 0,500 C 0,500 0,300 0,300 C 69.34867452135494,320.34278350515467 138.69734904270987,340.6855670103093 191,333 C 243.30265095729013,325.3144329896907 278.55927835051546,289.60051546391753 334,278 C 389.44072164948454,266.39948453608247 465.06553755522816,278.9123711340206 529,287 C 592.9344624447718,295.0876288659794 645.1785714285714,298.74999999999994 710,310 C 774.8214285714286,321.25000000000006 852.2201767304861,340.0876288659794 917,329 C 981.7798232695139,317.9123711340206 1033.9407216494844,276.8994845360824 1090,274 C 1146.0592783505156,271.1005154639176 1206.016936671576,306.3144329896908 1265,317 C 1323.983063328424,327.6855670103092 1381.9915316642118,313.8427835051546 1440,300 C 1440,300 1440,500 1440,500 Z"
          stroke="none"
          stroke-width="0"
          fill="url(#gradient)"
          class="transition-all duration-300 ease-in-out delay-150 path-2"
        ></path>
        <defs>
          <linearGradient id="gradient" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="5%" stop-color="#00bfffff"></stop>
            <stop offset="95%" stop-color="#0059ffff"></stop>
          </linearGradient>
        </defs>
        <path
          d="M 0,500 C 0,500 0,400 0,400 C 54.017120765832104,404.3199558173785 108.03424153166421,408.63991163475697 172,413 C 235.9657584683358,417.36008836524303 309.88015463917526,421.7603092783506 370,411 C 430.11984536082474,400.2396907216494 476.4451399116348,374.31885125184095 530,365 C 583.5548600883652,355.68114874815905 644.3392857142857,362.9642857142857 700,370 C 755.6607142857143,377.0357142857143 806.1977172312224,383.8240058910162 868,385 C 929.8022827687776,386.1759941089838 1002.8698453608247,381.7396907216495 1069,393 C 1135.1301546391753,404.2603092783505 1194.3229013254786,431.2172312223859 1255,435 C 1315.6770986745214,438.7827687776141 1377.8385493372607,419.39138438880707 1440,400 C 1440,400 1440,500 1440,500 Z"
          stroke="none"
          stroke-width="0"
          fill="url(#gradient)"
          class="transition-all duration-300 ease-in-out delay-150 path-3"
        ></path>
      </svg>
    </div>
    <div class="h-full flex flex-col items-center justify-center z-30">
      <span
        class="font-spline text-9xl text-atl-2 text-center font-extrabold text-shadow-title xl:text-main animate-fade"
        >Atlas</span
      >
      <span
        class="font-spline text-xl text-atl-11 text-center font-semi text-shadow-black xl:text-5xl animate-fade mb-6"
        >{{ $t("DESCRIPTION") }}</span
      >
      <button
        class="relative transform hover:scale-105 animate-side w-48 h-14 bg-atl-10 font-semibold text-atl-1 text-shadow-black rounded border-2 border-atl-4 duration-150 hover:bg-atl-9 hover:text-atl-2 focus:ring-3 focus:ring-atl-3"
      >
        <router-link
          class="absolute top-0 left-0 flex items-center justify-center text-shadow-black font-semibold w-full h-full transform hover:scale-105"
          :to="{ name: 'Documentation' }"
          >{{ $t("GET_STARTED") }}</router-link
        >
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  setup() {
    return {};
  },
};
</script>
